import React from 'react'
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system'
import Heading from '../components/Heading/Heading.js'
import TextInput from '../components/inputs/TextInput/TextInput.js'
import Select from '../components/inputs/Select/Select.js'
import TextArea from '../components/inputs/TextArea/TextArea.js'
import Button from '../components/Button/Button.js'

const Contact = () => {
  return (
    <>
      <Heading>Contact</Heading>
      <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
        <ScreenClassRender
          /*render={screenClass => (*/
          render={() => (
            <Row nogutter>
              <Col sm={7} component="section" aria-label="Main content">
                <p className="gd-space-below-21">
                  Got a question, comment or inquery? I&aposd love to hear from you. Send a message and I&aposll respond
                  as soon as possible.
                </p>
                <form action="https://getform.io/f/f741d934-0957-4050-925d-22d6a2442ebc" method="POST">
                  <TextInput label="Name" name="name" id="name" key="name" />
                  <TextInput label="Email" name="email" id="email" key="email" />
                  <Select label="Subject" name="subject" id="subject" key="subject">
                    <option value="" />
                    <option value="general-inquiries">General inquiries</option>
                    <option value="work-opportunity">Work Opportunity</option>
                  </Select>
                  <TextArea
                    label="Message"
                    name="message"
                    id="message"
                    key="message"
                    lineCount={4}
                    marginBottom={{ xs: '21px', sm: '34px', md: '34px', lg: '34px', xl: '34px', xxl: '34px' }}
                    //className={['md', 'lg', 'xl', 'xxl'].includes(screenClass) ? 'gd-space-below-21' : 'gd-space-below-13'}
                  />
                  <Button type="submit" marginBottom={{ xs: '34px', sm: '34px', md: '34px' }}>
                    Send
                  </Button>
                </form>
              </Col>
              <Col sm={4} push={{ sm: 1 }} component="aside" aria-label="Supporting content">
                <address className="gd-Address">
                  <strong>69 West Elm ST</strong> <br />
                  <strong>Hopkinton, MA 01748</strong> <br />
                  <strong>Tel</strong> <span style={{ color: '#CFD1D2' }}>|</span>{' '}
                  <a href="tel:+19178638076">917.863.8076</a> <br />
                </address>
              </Col>
            </Row>
          )}
        />
      </Container>
    </>
  )
}

export default Contact
