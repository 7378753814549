export const isObject = obj => {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

export const getMargin = (screenClass, marginBottom) => {
  let customMargin = null
  if (isObject(marginBottom)) {
    return { marginBottom: marginBottom[screenClass] }
  }
  return customMargin
}

export const getRandomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
