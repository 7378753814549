import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ScreenClassRender } from 'react-grid-system'
import { getMargin } from '../../utilities/utils.js'
import './heading.scss'

const Heading = ({ children, className, id, level, marginBottom, tag }) => {
  const GetTag = tag || level
  return (
    <ScreenClassRender
      render={screenClass => (
        <GetTag
          className={classNames(
            'gd-Heading',
            `gd-Heading--${level}`,
            { 'gd-Heading--isMarginBottom': marginBottom !== false },
            className
          )}
          id={id}
          style={getMargin(screenClass, marginBottom)}
        >
          {children}
        </GetTag>
      )}
    />
  )
}
Heading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  marginBottom: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
}
Heading.defaultProps = {
  className: undefined,
  id: undefined,
  level: 'h1',
  marginBottom: true,
  tag: undefined,
}

export default Heading
