import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ScreenClassRender } from 'react-grid-system'
import { getMargin } from '../../../utilities/utils.js'
import IconCaret16 from '../../Icon/IconCaret16/IconCaret16.js'
import './select.scss'

const Select = ({ children, className, disabled, id, label, marginBottom, name }) => {
  const [isFocused, setIsFocused] = React.useState(false)

  return (
    <ScreenClassRender
      render={screenClass => (
        <div
          className={classNames(
            'gd-Input',
            'gd-Select',
            { 'gd-Select--disabled': disabled },
            { 'gd-Select--isFocused': isFocused },
            { 'gd-Select--isMarginBottom': marginBottom !== false },
            className
          )}
          id={id}
          style={getMargin(screenClass, marginBottom)}
        >
          <label htmlFor={name} className="gd-Input-label gd-Select-label">
            {label}
          </label>
          <div className="gd-Input-wrap gd-Select-wrap">
            <select
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className="gd-Input-input gd-Select-input"
              name={name}
              disabled={disabled}
            >
              {children}
            </select>
            <div className="gd-Select-icon">
              <IconCaret16 />
            </div>
          </div>
        </div>
      )}
    />
  )
}

Select.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  marginBottom: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

Select.defaultProps = {
  className: undefined,
  disabled: false,
  id: undefined,
  marginBottom: true,
  onChange: undefined,
}

export default Select
