import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ScreenClassRender } from 'react-grid-system'
import { getMargin } from '../../../utilities/utils.js'
import './textInput.scss'

const TextInput = ({ className, disabled, id, label, marginBottom, name }) => {
  const [isFocused, setIsFocused] = React.useState(false)

  return (
    <ScreenClassRender
      render={screenClass => (
        <div
          className={classNames(
            'gd-Input',
            'gd-TextInput',
            { 'gd-TextInput--disabled': disabled },
            { 'gd-TextInput--isFocused': isFocused },
            { 'gd-TextInput--isMarginBottom': marginBottom !== false },
            className
          )}
          id={id}
          style={getMargin(screenClass, marginBottom)}
        >
          <label htmlFor={name} className="gd-Input-label gd-TextInput-label">
            {label}
          </label>
          <div className="gd-Input-wrap gd-TextInput-wrap">
            <input
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className="gd-Input-input gd-TextInput-input"
              type="text"
              name={name}
              disabled={disabled}
            />
          </div>
        </div>
      )}
    />
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  marginBottom: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

TextInput.defaultProps = {
  className: undefined,
  disabled: false,
  id: undefined,
  marginBottom: true,
  onChange: undefined,
}

export default TextInput
