import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ScreenClassRender } from 'react-grid-system'
import { getMargin } from '../../../utilities/utils.js'
import './textArea.scss'

const TextArea = ({ className, disabled, id, label, lineCount, marginBottom, name }) => {
  const [isFocused, setIsFocused] = React.useState(false)

  return (
    <ScreenClassRender
      render={screenClass => (
        <div
          className={classNames(
            'gd-Input',
            'gd-TextArea',
            { 'gd-TextArea--disabled': disabled },
            { 'gd-TextArea--isFocused': isFocused },
            { 'gd-TextArea--isMarginBottom': marginBottom !== false },
            className
          )}
          id={id}
          style={getMargin(screenClass, marginBottom)}
        >
          <label htmlFor={name} className="gd-Input-label gd-TextArea-label">
            {label}
          </label>
          <div className="gd-Input-wrap gd-TextArea-wrap">
            <textarea
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className="gd-Input-input gd-TextArea-input"
              name={name}
              disabled={disabled}
              style={{ height: `${lineCount * 26}px` }}
            />
          </div>
        </div>
      )}
    />
  )
}

TextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  lineCount: PropTypes.number,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  marginBottom: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

TextArea.defaultProps = {
  className: undefined,
  disabled: false,
  lineCount: undefined,
  id: undefined,
  marginBottom: true,
  onChange: undefined,
}

export default TextArea
