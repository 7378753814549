import React from 'react'
import './iconCaret16.scss'

const IconCaret16 = () => {
  return (
    <svg version="1.1" id="menu" className="gd-Icon gd-IconCaret16" x="0px" y="0px" viewBox="0 0 16 16">
      <polyline className="gd-IconCaret16-line" points="0.41 5 8 11 15.59 5" />
    </svg>
  )
}

export default IconCaret16
